 @import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Audiowide&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Onest:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 @tailwind base;
 @tailwind components;
 @tailwind utilities;

 body {
   font-family: "Inter", sans-serif;
 }

 .oneset {
   font-family: "Onest", sans-serif;
 }

 .poppins {
   font-family: "Poppins", sans-serif;
 }

 .audiowide {
   font-family: "Audiowide", sans-serif;
 }

 .abeezee {
   font-family: "ABeeZee", sans-serif;
 }

 @font-face {
   font-family: "Ruberoid";
   src: url("./components/assets/font/Ruberoid-Regular.ttf");
 }

 .Ruberoid {
   font-family: "Ruberoid";

 }