 body {
   margin: 0%;
   padding: 0%;
   box-sizing: border-box;
   background-color: #05020A;
 }

 .hero-bg {
   background-image: url('./components/assets/png/hero-bg.webp');
   background-position: center bottom;
   background-size: cover;
 }

 .middle-bg {
   background-image: url('./components/assets/png/middle-bg.webp');
   background-position: center top;
   background-size: 100% 100%;
 }

 .heading {
   background: linear-gradient(170deg, #FFF 31.39%, #8D3AF9 92.34%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
 }

 .layer-box {
   background: linear-gradient(180deg, #15101D 0%, #0D0A12 100%);
 }

 .layer-box .layer2 {
   background: linear-gradient(180deg, #211A2E 0%, rgba(7, 7, 9, 0.00) 100%);
 }

 .layer-box .layer2 .layer-3 {
   background: linear-gradient(180deg, rgba(30, 25, 41, 0.00) 0%, rgba(141, 58, 249, 0.41) 100%);
 }

 .work-bg {
   background-image: url('./components/assets/png/btn-bg.png');
   background-position: center;
   background-size: 100% 100%;
   background-repeat: no-repeat;
 }

 .roadmap-bg {
   background-image: url('./components/assets/png/roadmap-bg.webp');
   background-position: center top;
   background-size: 100% 100%;
 }

 .card-bg {
   border-radius: 24px;
   border: 2px solid rgba(121, 94, 153, 0.00);
   background: radial-gradient(72.73% 72.73% at 50% 0%, #231A30 0%, rgba(19, 15, 25, 0.40) 100%);
   backdrop-filter: blur(23.399999618530273px);
 }

 .bg-gradient {
   background: linear-gradient(270deg, rgba(41, 39, 65, 0.00) 0.21%, #383453 49.37%, rgba(41, 39, 65, 0.00) 101.23%);
 }